.Home__wrapper {
  .MuiButton-contained {
    background-color: $color-brand;
    color: $color-white;

    &:hover {
      background-color: $color-header-update;
    }
  }
}
