.AppBar__content {
  background-color: $color-brand;
  background-image: none;
}

.AppBar__title-logo {
  margin-right: 5px;
  font-weight: bold;

  img {
    height: 30px;
    margin-right: 15px;
  }
}

.AppBar__actions .MuiButton-contained {
  box-shadow: none;
}
