// variables
$color-white: #fff;
$color-black: #000;
$color-grey1: #bdbdbd;
$color-brand: #e42758;
$color-brand-dark: #990e39;
$color-red: #f00;
$color-header-btn-action: $color-brand;
$color-header-create: #36d7ad;
$color-header-update: #5c367d;
$bg-header-main: $color-brand;
$bg-header-btn-action: $color-white;
$bg-footer: #212121;
//
// font sizes
$font-size-h1: 36px;
$font-size-h2: 32px;
$font-size-h3: 28px;
$font-size-h4: 25px;
$font-size-h5: 21px;
$font-size-h6: 18px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-xsmall: 12px;
